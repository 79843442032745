import GATSBY_COMPILED_MDX from "/home/runner/work/discretize.eu/discretize.eu/discretize-guides/guides/teamcomp101/index.md";
import {TextDivider} from '@discretize/react-discretize-components';
import {graphql} from 'gatsby';
import React from 'react';
import Template from '.';
import GuideHeader from '../components/navigation/GuideHeader';
import ThumbnailContext from '../state/context/ThumbnailContext';
const createDefaultDescription = title => `Learn everything about ${title} in Guild Wars 2. Our general guides are designed to improve your knowledge of game basics.`;
function GuideTemplate({location, children, data: {guide: {frontmatter: {title, date, author, description: customDescription, image: {childImageSharp: img}}}}}) {
  const renderHeader = () => {
    return React.createElement(React.Fragment, null, React.createElement(GuideHeader, {
      title: title,
      date: date.trim(),
      author: author
    }), React.createElement(TextDivider, {
      text: "Guide"
    }));
  };
  const wrapMarkdown = markdown => {
    return React.createElement(ThumbnailContext.Provider, {
      value: img.gatsbyImageData
    }, markdown);
  };
  const description = customDescription || createDefaultDescription(title);
  const imagePath = img && img.gatsbyImageData && img.gatsbyImageData.images.fallback.src || null;
  return React.createElement(Template, {
    location: location,
    SeoProps: {
      title,
      description,
      imagePath
    },
    markdown: children,
    renderHeader: renderHeader,
    wrapMarkdown: wrapMarkdown
  });
}
GuideTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(GuideTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query GuideQuery($id: String!) {
    guide: mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        date
        author
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
